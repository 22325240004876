import {inject, Injectable, NgZone} from '@angular/core';
import {MessagesApiService} from "../api/ws.service";
import {PaymentsService} from "./payments.service";
import {StorageDataKey, StorageService} from "./storage.service";
import {Router} from "@angular/router";
import {UserRepositoryService} from "../repository/user-repository.service";
import {PostsService} from './posts.service';
import {ApiService} from "../api/api.service";
import {BehaviorSubject} from "rxjs";
import {SsrCookieService} from "ngx-cookie-service-ssr";
import {TranslationsService} from "./translations/translations.service";
import {ELanguages} from "desiren-core-lib/lib/enums/languages.enum";

export interface LoggedInData {
  returnUrl?: string;
  isLoggedIn: boolean;
}

@Injectable()
export class SessionService {
  private cookie = inject(SsrCookieService);
  private translationsService = inject(TranslationsService)
  private api = inject(ApiService);
  private postsService =  inject(PostsService);
  private ws = inject(MessagesApiService);
  private payments = inject(PaymentsService);
  private storage = inject(StorageService);
  private router = inject(Router);
  private zone = inject(NgZone);
  private userRepo = inject(UserRepositoryService);
  private postService = inject(PostsService);

  public onLoggedInSub = new BehaviorSubject<LoggedInData>({
    isLoggedIn: false,
  });

  async login(idToken: string, returnUrl?: string) {
    // @ts-ignore
    google.accounts.id.storeCredential(idToken);
    const responsePayload = SessionService.decodeJWTToken(idToken);
    this.storage.setLocalJsonData(StorageDataKey.socialUser, responsePayload);
    if (this.storage.getLocalData(StorageDataKey.browserId) == null) {
      this.storage.setLocalData(StorageDataKey.browserId, this.cookie.get('browserId'));
    }
    this.api.loginGoogle({
      token: idToken,
      referrerCode: this.storage.getLocalData(StorageDataKey.referrerCode) ?? '',
      fcmToken: this.storage.getLocalData(StorageDataKey.fcmToken) ?? null,
      browserId: this.storage.getLocalData(StorageDataKey.browserId) ?? '',
      language: this.translationsService.getActiveLang() as ELanguages,
    }).then(result => {
      this.storage.setLocalData(StorageDataKey.jwtToken, result['jwtToken']);
      this.storage.clearLocalData(StorageDataKey.referrerCode);
      this.postsService.clearPosts();
      this.onLoggedInSub.next({
        isLoggedIn: true,
        returnUrl: returnUrl,
      })
    });
  }
  async logout() {
    try {
      this.ws.logout();
      this.payments.clear();
      // @ts-ignore
      google.accounts.id.disableAutoSelect();
      this.storage.clearLocalStorage();
    } finally {
      this.userRepo.meSubject.next(null);
      this.postService.clearPosts();
      this.zone.run(() => {
        this.router.navigate(['/home']).then(_ => {
          this.router.navigate([this.router.url]);
        });
      });
    }
  }

  private static decodeJWTToken(token){
    return JSON.parse(atob(token.split(".")[1]));
  }
}
